import { getChannelCode, getPublicEnv } from '../env/env';
import {
    BlockItem,
    BlockTranslation,
    CountryResponse,
    GetProjectResponse,
    ProjectResponseCustomOptionsValues,
    ProjectResponseNode,
    SaveProjectMainItemResponse,
    SaveProjectRequestParam,
    SaveProjectResponse,
} from '../../types/Project';
import { getGenericObject, getNumber, getString, getBoolean, isGenericArray, isObjectWithKey } from '../validateData';
import { ProjectImage, ProjectImageType } from '../../types/Cart';
import { FetchService, fetchUnAuthorizationApi } from '../fetch/fetch';

export function isGetProjectResponse(data: unknown): data is GetProjectResponse {
    return isObjectWithKey(data) && typeof data.id === 'string' && isProjectResponseNode(data.mainItem);
}

function isProjectResponseCustomOptionsValues(data: unknown): data is ProjectResponseCustomOptionsValues {
    return (
        isObjectWithKey(data) &&
        typeof data.value === 'string' &&
        isObjectWithKey(data.threeDParameter) &&
        typeof data.threeDParameter.genericCode === 'string'
    );
}

function isSaveProjectMainItemResponse(data: unknown): data is SaveProjectMainItemResponse {
    return (
        isObjectWithKey(data) &&
        isGenericArray(data.images, (image: unknown): image is ProjectImage => isObjectWithKey(image) && typeof image.path === 'string') &&
        typeof data.unitPriceWithChildItems === 'number'
    );
}

function isProjectResponseNode(data: unknown): data is ProjectResponseNode {
    return (
        isObjectWithKey(data) &&
        typeof data.product === 'string' &&
        isGenericArray(data.childItems, isProjectResponseNode) &&
        isGenericArray(data.threeDParametersValues, isProjectResponseCustomOptionsValues) &&
        (typeof data.layout === 'string' || data.layout === null)
    );
}

function isBlockTranslation(data: unknown): data is BlockTranslation {
    return isObjectWithKey(data) && typeof data.content === 'string';
}

export class ProjectService {
    private fetch: FetchService;
    constructor(fetch: FetchService) {
        this.fetch = fetch;
    }
    async saveProject({
        imagesIri,
        projectItemFilesIri,
        unit,
        ...data
    }: SaveProjectRequestParam): Promise<SaveProjectResponse> {
        const responseData = await this.fetch.post('/api/v2/shop/projects', {
            ...data,
            unit,
            mainItem: {
                ...data.mainItem,
                images: imagesIri,
                projectItemFiles: projectItemFilesIri,
            },
        });

        if (!isObjectWithKey(responseData)) {
            throw new Error('Invalid response');
        }

        if (!isSaveProjectMainItemResponse(responseData.mainItem)) {
            throw new Error('Invalid response mainItem');
        }

        return {
            id: getString(responseData.id),
            mainItem: responseData.mainItem,
        };
    }
}

export const getProject = async (id: string): Promise<GetProjectResponse> => {
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');
    const mediaUrl = await getPublicEnv('MEDIA_DOMAIN_URL');

    const responseData = await fetchUnAuthorizationApi(`${apiUrl}/shop/projects/${id}`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (isGetProjectResponse(responseData)) {
        return {
            ...responseData,
            mainItem: {
                ...responseData.mainItem,
                images: responseData.mainItem.images.map((img) => ({
                    ...img,
                    path: `${mediaUrl}${img.path}`,
                })),
            },
        };
    }
    throw new Error(`Invalid data from '${apiUrl}/shop/projects/${id}' URL`);
};

export const getBlockItems = async (): Promise<BlockItem[]> => {
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');

    const responseData = await fetchUnAuthorizationApi(`${apiUrl}/shop/cms-plugin/blocks`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!isObjectWithKey(responseData)) {
        throw new Error('Invalid response');
    }

    if (Array.isArray(responseData)) {
        const formattedBlockItems = responseData.map(
            (blockItem): BlockItem => ({
                id: getNumber(blockItem.id),
                code: getString(blockItem.code),
                translations: getGenericObject(blockItem.translations, isBlockTranslation),
            }),
        );

        return formattedBlockItems;
    }

    return [];
};

export const getAvailableCountries = async (): Promise<CountryResponse[]> => {
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');

    const responseData = await fetchUnAuthorizationApi(`${apiUrl}/shop/shipping-methods/default_shipping_method`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (
        isObjectWithKey(responseData) &&
        isObjectWithKey(responseData.zone) &&
        Array.isArray(responseData.zone.members)
    ) {
        const formattedCountries = responseData.zone.members.map(
            (country): CountryResponse => ({
                code: getString(country?.code),
            }),
        );

        return formattedCountries;
    }

    return [];
};

export const getAllCountries = async (): Promise<CountryResponse[]> => {
    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');

    const responseData = await fetchUnAuthorizationApi(`${apiUrl}/shop/countries`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (Array.isArray(responseData)) {
        const formattedCountries = responseData.map(
            (country): CountryResponse => ({
                code: getString(country?.code),
            }),
        );

        return formattedCountries;
    }

    return [];
};

export const getProjectImageByType = (images: ProjectImage[], imageType: ProjectImageType) =>
    images.find((image) => image.type === imageType)?.path || images[0]?.path;
