import styled, { css } from 'styled-components';
import Breakpoint from '../../constants/Breakpoint.enum';
import { Color } from '../../constants/Color.enum';

export const SIDEBAR_DESKTOP_WIDTH = '440px';
const SIDEBAR_MOBILE_WIDTH = '315px';
const STICKY_FOOTER_HEIGHT = '84px';

interface SidebarProps {
    isOpen: boolean;
    disabled?: boolean;
    backgroundColor?: Color;
    zIndex?: number;
    isMobileFullWidth?: boolean;
}

interface StickyFooterProps {
    backgroundColor?: Color;
    padding?: string;
    height?: string;
}

const SidebarStyles = css<SidebarProps>`
    background-color: ${({ backgroundColor }) => backgroundColor || Color.Surface};
    width: ${SIDEBAR_DESKTOP_WIDTH};
    height: calc(100% - var(--dynamic-offset));
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0%)' : 'translateX(100%)')};
    opacity: ${({ disabled }) => disabled && '0.3'};
    transition: all 0.5s;

    @media (max-width: ${Breakpoint.Tablet}) {
        width: ${({ isMobileFullWidth }) => (isMobileFullWidth ? '100%' : SIDEBAR_MOBILE_WIDTH)};
    }
`;

export const StickyFooterStyled = styled.div<StickyFooterProps>`
    padding: ${({ padding }) => padding || '12px 32px 32px 32px'};
    background-color: ${({ backgroundColor }) => backgroundColor || Color.Surface};
    position: sticky;
    align-items: center;
    display: flex;
    height: ${({ height }) => height || STICKY_FOOTER_HEIGHT};
    width: 100%;
    bottom: 0;
    margin: 0;
    justify-content: space-between;
    z-index: 11;
`;

export const OverlayStyled = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: ${Color.Alpha_30};
`;

export const TitleWrapperStyled = styled.div`
    position: relative;
    height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 32px;

    @media (max-width: ${Breakpoint.Tablet}) {
        padding: 60px 24px 28px 24px;
    }
`;

export const TitleWrapperStyledSmall = styled.div`
    position: relative;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -24px;
    gap: 0 32px;

    @media (max-width: ${Breakpoint.Tablet}) {
        padding: 60px 24px 28px 24px;
    }
`;

export const CarouselContainerStyled = styled.div<SidebarProps>`
    width: 0;

    .slider-wrapper {
        ${SidebarStyles}
        z-index: ${({ zIndex }) => zIndex || 10};
        padding: 0;

        @media (max-width: ${Breakpoint.Tablet}) {
            z-index: 100;
            display: flex;
        }
    }
`;

export const SlideWrapperStyled = styled.div`
    width: 100%;
    padding: 0 32px 0 32px;

    @-moz-document url-prefix() {
        margin-bottom: ${STICKY_FOOTER_HEIGHT};
    }

    @media (max-width: ${Breakpoint.Tablet}) {
        padding: 0 24px;
        text-align: left;
    }
`;

export const CarouselWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-direction: column;
    transition: height 0.5s;
`;
