export default function imageLoader({ src, width, quality }) {
    if(src === undefined) {
        return undefined;
    }

    if(src.indexOf('media/image/') !== -1){
        return `${src}?width=${width}`
    }


    return src;
}
