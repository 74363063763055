var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2.120.7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from '@sentry/nextjs';
import { addEnvironmentNameToSentry } from './services/env/addEnvironmentNameToSentry';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const isNotLocalEnv = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'local';

if (isNotLocalEnv) {
    init({
        dsn: SENTRY_DSN || 'https://a545413a0e184a3683994bb43409a4de@o168653.ingest.sentry.io/6107963',
        tracesSampleRate: 1.0,
        environment: 'Client',
        release: `${process.env.SENTRY_RELEASE}`,
    });

    // eslint-disable-next-line no-console
    console.log(`App version: ${process.env.SENTRY_RELEASE}`);

    addEnvironmentNameToSentry();
}
