import { CurrencySettings } from '../currency/currency';
import { getApiByCurrentHost, getChannelCode, getPublicEnv } from '../env/env';
import { fetchUnAuthorizationApi } from '../fetch/fetch';
import { getString } from '../validateData';

export interface Channel {
    name: string;
    logo: string | null;
    favicon: string | null;
    defaultUnit: 'MM' | 'DIN';
    locales: {
        code: string;
        name: string;
    }[];
    currencies: string[];
    defaultCurrency: string;
    handledUnits: ('MM' | 'DIN')[];
}
const cache: { [channelCode: string]: Promise<Channel> } = {};

export class ChannelService {
    async fetchChannel(channelCode: string): Promise<Channel> {
        const formCache = cache[channelCode];

        if (formCache) {
            return formCache;
        }

        return (cache[channelCode] = new Promise<Channel>((resolve, reject) => {
            getPublicEnv('NEXT_PUBLIC_API_URL').then(async (apiUrl) => {
                const endpoint = `${apiUrl}/shop/channels/${channelCode}`;

                const response = await fetchUnAuthorizationApi(endpoint, {
                    headers: {
                        accept: 'application/json',
                    },
                });

                const { host } = getApiByCurrentHost();

                if (isChannelResponse(response)) {
                    resolve({
                        defaultUnit: getString(response.baseUnit, 'MM') === 'MM' ? 'MM' : 'DIN',
                        name: getString(response.name, ''),
                        logo: `${host}/media/image/${response.image.path}`,
                        favicon: response.thirdImage?.path ? `${host}/media/image/${response.thirdImage?.path}` : null,
                        locales: response.locales,
                        currencies: response.currencies.map((currency) => currency.code),
                        defaultCurrency: response.baseCurrency.code,
                        handledUnits: response.handledUnits,
                    });
                }

                reject(new Error(`Invalid data from '${endpoint}' URL`));
            });
        }));
    }

    async getAvailableLanguages(): Promise<
        {
            code: string;
            name: string;
        }[]
    > {
        const response = await this.fetchChannel(getChannelCode());

        return response.locales;
    }

    async getCurrencySettings(): Promise<CurrencySettings> {
        const response = await this.fetchChannel(getChannelCode());

        return {
            currencies: response.currencies,
            defaultCurrency: response.defaultCurrency,
        };
    }
}

interface ChannelResponse {
    name: string | null;
    image: {
        path: string;
    };
    thirdImage: {
        path: string;
    } | null;
    baseUnit: string;
    locales: {
        code: string;
        name: string;
    }[];
    defaultLocale: {
        code: string;
    };
    currencies: {
        code: string;
    }[];
    baseCurrency: {
        code: string;
    };
    handledUnits: ('MM' | 'DIN')[];
}

function isChannelResponse(value: any): value is ChannelResponse {
    return (
        value &&
        value.image &&
        value.image.path &&
        value.name &&
        (value.thirdImage === null || value.thirdImage.path) &&
        typeof value.baseUnit === 'string' &&
        Array.isArray(value.locales) &&
        value.locales.every(isLanguage) &&
        isLanguage(value.defaultLocale) &&
        Array.isArray(value.currencies) &&
        value.currencies.every(isCurrency) &&
        isCurrency(value.baseCurrency) &&
        Array.isArray(value.handledUnits) &&
        value.handledUnits.every((unit: string) => 'MM' === unit || 'DIN' === unit)
    );
}

function isLanguage(value: any): value is { code: string } {
    return (
        typeof value === 'object' && value !== null && typeof value.code === 'string' && typeof value.name === 'string'
    );
}

function isCurrency(value: any): value is { code: string } {
    return typeof value === 'object' && value !== null && typeof value.code === 'string';
}
